.header {
  max-width: 1440px;
  margin: 0 auto;
  position: sticky;
  top: 0;
  /* z-index: 10; */
  padding: 0 64px;
  background-color: blue;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: linear-gradient(180deg, #001233 0%, #001233 100%);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
  display: flex;
  align-self: stretch;
}

.header .buttons {
  display: flex;
  gap: 40px;
}

.header .buttons .select {
  border: none;
  background-color: transparent;
  color: var(--M3-web-exatracted-neutral-n-100, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.2px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.btn-uparrow {
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: center;
  margin-top: 4px;
}

.btn-Solutions {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: center;
  margin-top: 14px;
}

.text {
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.drop-down-options {
  display: flex;
  position: absolute;
  transition: 2s;
  width: 100%;
  max-width: 100%;
  left: 0;
  top:85px;
  min-height: 250px;
  background-color: #002866;
  /* animation: dropdown 1s ease; */
  border-radius: 0 0 32px 32px;
}

@media only screen and (max-width: 768px) {
  .popup-layers {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 576px) {
  .popup-layers {
    flex-wrap: wrap;
  }
}

.transformation-popup {
  display: flex;
  width: 280px;
  height: 118px;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: #FFF7F5;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.popup-layers {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  gap: 24px;
  flex: 1 0 0;
}

.pop-info {
  display: flex;
  padding: 4px 0px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.popup-text {
  flex: 1 0 0;
  color: #191B23;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 116%;
  /* 18.56px */
  text-transform: uppercase;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  color: #FFF; /* Adjust the color as needed */
  font-size: 24px; /* Adjust the font size as needed */
}
.popup-readmore {
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid var(--M3-web-exatracted-primary-shades-p-10, #001233);
}

@keyframes dropdown {
  0% {
    top: 50%;
  }

  100% {
    top: 70PX;
  }
}

@media only screen and (max-width: 599px) {
  .btn-uparrow {
    display: inline-flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: center;
    margin-top: 10px;
  }
  .drop-down-options{
    top: 60px;
  }
  .close-symbol{
    display: none;
  }
}
