.background-homepage {
  background-image: url("../asserts/Images/newherobanner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* max-width: 1440px; */
  margin: 0 auto;
  height: 720px;
  text-align: center;
  justify-content: center;
  position: center;
}

.main-home {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 32px 64px 0 64px;
}

.main-home .home-text {
  flex: 1 0 0;
  font-family: "IBM Plex Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 57.6px;
  /* 120% */
  text-transform: capitalize;
  align-items: center;
  justify-content: center;
}

.home-text p {
  margin-top: 40px;
  margin-bottom: 40px;
}

.main-home .home-image {
  flex: 1 0 0;
}

.home-image img {
  object-fit: cover;
  justify-content: center;
  align-items: center;
}

.home-banner-img {
  display: flex;
  width: 1440px;
  height: 832px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.heroflight {
  display: none;
}

.main-home .home-text .title {
  font-weight: 300;
}

.coonect-home-mobile {
  display: none !important;
}
.coonect-home-desktop :hover{
  color: white;
}

.connect-home-desktop img:hover {
  color: white !important;
}

@media only screen and (max-width: 599px) {
  .homeBannerFirst {
    display: none;
    width: 328px;
    height: 328px;
    padding: 0px 0.399px 0px 5px;
    justify-content: flex-end;
    align-items: center;
  }
  .main-home {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px 16px;
  }
  .heroflight {
    display: flex;
  }
  .home-text  {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  .background-homepage {
    height: 640px;
  }
  .coonect-home-desktop{
    display: none !important;
  }
  .coonect-home-mobile {
    display: inline !important;
  }
  .home-image img {
    object-fit: cover;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
}