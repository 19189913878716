.who-are-we {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 64px;
  /* z-index: 8; */
}

.who-are-we .p {
  margin: 0px;
}

.titles {
  font-family: "IBM Plex Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  /* 120% */
  /* text-transform: capitalize; */
}
.product-solution-name {
  margin-top: 0 !important;
}

.content {
  display: flex;
  flex-direction: column;
  color: var(--M3-web-exatracted-primary-shades-p-95, #e5e8ff);
  font-family: "IBM Plex Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* text-transform: capitalize; */
  margin: 0 !important;
  text-align: justify;
}

.liness {
  display: flex;
  align-items: start;
}

.liness li,
.liness p {
  margin: 0; /* Remove default margin */
}

.who-are-we .abt-content {
  padding-bottom: 64px;
  padding-top: 40px;
}
.abt-content {
  padding-bottom: 64px;
}

.who-are-we .card-items {
  display: flex;
  gap: 48px;
}

.who-are-we .card-items .cards {
  padding: 48px 24px;
  background: var(--gradu3, linear-gradient(180deg, #000b1e 0%, #042051 100%));
  border: 1px solid #fff;
  border-radius: 16px;
}

.who-are-we .card-items .cards .card-title {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  gap: 16px;
}

.mission-img {
  display: flex;
}
.mission-space {
  padding-left: 15px;
}

.who-are-we .card-items .cards .card-title .title-name {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  text-transform: capitalize;
}

.service-card {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 16px;
  
}

.bookings-background {
  background-image: url("../asserts/Images/transformationalBookingNew.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 16px 16px 0 0;
  /* object-fit: cover; */
}

.business-process {
  background-image: url("../asserts/Images/bpo\ bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 16px;
  object-fit: cover;
}

.small-tabs {
  width: fit-content;
  padding: 8px;
}

.center {
  text-align: center;
  margin: 0px;
  margin-bottom: 80px;
  /* position: relative; */
  padding-top: 64px;
}

/* .service-card .services .img-service {
  position: relative;
} */

.services .img-service .bookings {
  display: flex;
  align-items: center;
  padding: 0px 0px 48px 40px;
  justify-content: space-between;
}

.img-service .bookings .content {
  flex: 1 0 0;
  justify-content: space-between;
  text-align: justify;
}

.services .img-service .bookings img {
  flex: 1 0 0;
}

.services .img-service .bookings .service-text {
  color: var(--M3-web-exatracted-neutral-n-99, #fefbff);
  text-align: justify;
  font-family: "IBM Plex Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.services .img-service .bookings .content .main-small-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 64px;
  text-align: justify;
}
.main-small-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.product-soluctions {
  background-color: #891E00;
  border-radius: 0 0 16px 16px !important;
}

.services .img-service .product-soluctions {
  padding: 0px 48px;
  padding-bottom: 48px;
}

.horizontal-spaces {
  padding: 0 0 64px 48px;
}
.include-transform-solutions{
  background-color: #891E00;
  border-radius: 16px;
}

.services .img-service .product-soluctions .name {
  color: var(--M3-web-exatracted-primary-shades-p-100, #fff);
  font-family: "IBM Plex Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  text-transform: capitalize;
}

.services .img-service .product-soluctions .list-items {
  margin-left: 18px;
}

.services .img-service .product-soluctions .card-items {
  gap: 24px;
}

.services .img-service .product-soluctions .cards {
  flex: 1;
}

/* .business-process {
  background-image: url("../asserts/svgs/businessProcessBanner.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 32px;
} */

.digital-transformation {
  background-image: url("../asserts/Images/digital\ transformation.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 16px;
  object-fit: cover;
}

.digital-content {
  color: var(--M3-web-exatracted-neutral-n-99, #fefbff);
  /* text-align: center; */
  font-family: "IBM Plex Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.industires .industires-title {
  margin: 40px 0px;
  text-align: center;
}

.industires .images {
  display: flex;
  padding: 0px 24px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 64px;
}

.industires .images .children {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
}

.industires .images .children .heading {
  color: #fff;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  max-width: 160px;
  margin-top: 10px;
}

.zen3-card {
  margin: 128px 240px;
}

.zen3-card .card {
  background-image: url('../asserts/Images/cta\ bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 16px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 64px 32px;
  object-fit: cover;
}

.zen3-card .card .heading {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  padding: 0px 20px;
}

.main-footer {
  background-image: url("../asserts/Images/footer-banner.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0;
  /* max-width: 1440px; */
  margin: 0 auto;
}

.who-are-we-main-content {
  background: linear-gradient(180deg, #00337C 0%, rgba(0, 18, 51, 0.00) 100%);
  padding: 32px 64px;
}
.content-scroll{
  max-width: 1440px;
  margin: 0 auto;
}
.mobile_desktop {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .bookings {
    display: none;
  }

  .bookings p {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .bookings {
    display: flex;
  }

  .bookings p {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 599px) {
.card-items {
 display: inline;
}
.who-are-we {
  padding: 15px 16px
}
.titles p{
  font-size: 32px;
}
.content{
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: justify;
}
.abt-content {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}
.horizontal-title {
  font-size: 24px !important;
  line-height: 30px !important;
}
.horizontal-paragraph p{
  margin-top: 0 !important;
}
.travel_saas_img h3 {
  margin-left: 0 !important;
  margin-top: 24px !important;
}
.digital-content {
  font-size: 16px !important;
  line-height: 26px !important;
 text-align: justify !important;
}
.heading  {
   font-size: 20px !important;
   line-height: 24px !important;
}
.industires .industires-title {
 margin: 24px !important;
}
/* .card {
  padding: 0 !important;
  padding-bottom: 32px !important;
} */
.title-name {
  font-size: 24px !important;
  line-height: 30px !important;
}
.mission-img img{
  margin-top: -6px;
}
.btn-uparrow {
  padding: 8px 24px;
}
.bookings {
  display: flex;
}
.center{
  margin-bottom: 24px !important;
  padding-top: 48px !important;
}
.product-soluctions {
  display: none;
}
.horizontal-spaces {
  padding: 0 20px 15px 16px;
}
.horizontal-transformv {
  padding: 25px 0 0 0;
}
.mobile_vesrion{
  flex-direction: row-reverse !important;
  border-radius: 16px;
}
.mobileV{
  order: 2;
}
.mobileT{
  order: 1;
}
.allimages {
  display: flex;
  flex-wrap: nowrap; /* Prevent items from wrapping to the next line */
  overflow-x: auto; /* Enable horizontal scrolling if content overflows */
  gap: 20px; /* Spacing between items */
}

.allchildren {
  flex: 0 0 auto; /* Let items grow and shrink based on available space */
  text-align: center; /* Center align text */
}

.allheading {
  color: #fff;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  max-width: 160px;
  margin-top: 10px;
}

.allchildren img {
  max-width: 100%; /* Ensure images fit within their containers */
  height: auto; /* Maintain aspect ratio */
}
.display_desktop {
  display: none !important;
}
.mobile_desktop {
  margin-top: 50px;
  display: flex !important;
}
.who-are-we-main-content{
  padding: 16px 24px;
}
.include-transform-solutions{
  background-color: #891E00;
  border-radius: 16px;
}
.readmore_button {
  margin-left: 10px;
}
}