.active{
    background-color: #1d4ed8;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0f172a;
    color: white;
    position: sticky;
    top: 0;
    /* background: linear-gradient(180deg, #001233 0%, #001233 100%); */
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    display: flex;
    align-self: stretch;
    margin: 0 auto;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 0 20px;
  max-width: 1440px;
}
.header_class {
    justify-content: space-between;
    align-items: center;
    background-color: #0f172a;
    color: white;
    position: sticky;
    top: 0;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    position: sticky;
}

nav .title {
    font-size: 1.5rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

nav ul {
    display: flex;
}

nav ul li {
   list-style: none; 
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: white;
    /* padding: 0.5rem; */
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}

/* nav ul li a:not(.active):hover {
    background-color:#172554 ;
} */

nav .menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 1.2rem;
    padding: 2px 8px 12px 8px;
}

nav .menu span {
    height: 0.2rem;
    width: 100%;
    background-color: #fff;
    border-radius: 0;
}



@media (max-width: 480px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}
@media only screen and (max-width: 320px)  {
    .btn-Solutions{
       position: center;
       justify-content: center;
       text-align: center;
       margin-top: -20px;
    }
    .transformation-popup {
        display: flex;
        width: 260px;
        height: 118px;
        padding: 15px 10px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 16px;
        border-radius: 16px;
        background: #FFF7F5;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    }
    .close-icon {
        position: absolute;
        top: 10px;
        right: 2px;
        cursor: pointer;
        color: #FFF; /* Adjust the color as needed */
        font-size: 24px; /* Adjust the font size as needed */
      }
}