.background-bookings {
    background-image: url("../asserts/Images/bookings-banner.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* max-width: 1440px; */
    margin: 0 auto;
    padding: 16px 64px;
}

.background-business {
    background-image: url("../asserts/Images/business-banner.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* max-width: 1440px; */
    margin: 0 auto;
    padding: 16px 64px;
}



.background-digital {
    background-image: url("../asserts/Images/digital-banner.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* max-width: 1440px; */
    margin: 0 auto;
    padding: 15px 64px;
}

/* Base styles */
.main-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 64px;
    background-color: #001233;
}


.breadcrumb ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.breadcrumb li {

    font-size: 14px;
    padding: 10px 0;
    color: var(--M3-web-exatracted-neutral-n-100, #FFF);
    border-bottom: 1px solid #FFF;
}

.breadcrumb li+li:before {
    padding: 5px;
    color: black;
    content: "/\00a0";
    color: var(--M3-web-exatracted-neutral-n-100, #FFF);
}

.heading-title {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    color: var(--M3-web-exatracted-primary-shades-p-100, #FFF);
    font-family: "IBM Plex Sans";

    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    /* 120% */
    text-transform: capitalize;
}

.paragraph {
    color: var(--M3-web-exatracted-primary-shades-p-100, #FFF);
    text-align: justify;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 40px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .main-container {
        padding: 10px;
    }

    .breadcrumb li {
        font-size: 12px;
    }

    .paragraph {
        font-size: 14px;
    }
}

/* Existing styles */

.image-container {
    text-align: center;
    /* margin-top: 64px; */
    /* Adjust margin as needed */
}

.image-container img {
    max-width: 100%;
    object-fit: cover;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.title-container {
    text-align: center;
    margin: 48px 0;
}

.title-text {
    color: var(--M3-web-exatracted-primary-shades-p-95, #E5E8FF);
    text-align: center;
    justify-content: center;
    font-family: "IBM Plex Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    /* text-transform: capitalize; */
}

.horizontal-view {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 48px; */
}

.horizontal-item {
    margin: auto 0;
    flex-basis: 45%;
}

.horizontal-item img {
    max-width: 100%;
    height: auto;
    /* margin-right: 10px; */
}

.horizontal-title {
    color: var(--M3-web-exatracted-primary-shades-p-100, #FFF);
    font-family: "IBM Plex Sans";
    font-size: 36px;
    /* margin-top: 150px; */
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    /* 120% */
    text-transform: capitalize;

}

.space-bt {
    margin-left: 10px;
}

.travel_saas_img {
    display: flex;
    /* margin-top: 60px; */
}

.travel_saas_img h3 {
    margin-top: 40px;
    /* margin-left: 10px; */
}

.horizontal-content {
    color: var(--M3-web-exatracted-error-e-99, #FFFBFF);
    justify-content: center;
    align-items: center;
    font-family: "IBM Plex Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: justify;
    /* 150% */
}

.horizontal-arrow {
    font-size: 24px;
    color: blue;
}


/* Media queries for responsive design */
@media only screen and (max-width: 768px) {
    .horizontal-view {
        flex-wrap: wrap;
    }

    .horizontal-item {
        flex-basis: 100%;
    }

    .horizontal-title {
        font-size: 18px;
        margin-top: 1px;
    }

    .horizontal-content {
        font-size: 14px;
    }

    .horizontal-arrow {
        display: none;
        /* Hide the arrow on smaller screens */
    }
}

.horizontal-paragraph {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.horizontal-paragraph img {
    margin-right: 10px;
    /* Adjust the value as needed */
}

.horizontal-paragraph p {
    margin-top: -10px;
    /* Adjust the value as needed */
}

.gobal-image {
    margin-top: 36px;
    border-radius: 32px;
    background-color: #851800;
}

.platform_image {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .gobal-image {
        margin-top: -1px;
    }

    .platform_image {
        margin-top: 80px;
    }

}

@media (max-width: 576px) {
    .gobal-image {
        margin-top: -1px;
    }

    .platform_image {
        margin-top: 80px;
    }

}

@media (max-width: 768px) {
    .travel_saas_img {
        margin-top: -1px;
    }

    .booking-airline {
        margin-top: 50px;
    }
}

@media (max-width: 576px) {
    .travel_saas_img {
        margin-top: -1px;
    }

    .booking-airline {
        margin-top: 50px;
    }
}


/* Global styles */

.card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    /* padding: 20px; */
}

.cards {

    flex: 1 1 calc(23.33% - 14px);
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #FFF;
    background: var(--gradu3, linear-gradient(180deg, #000B1E 0%, #042051 100%));
}

@media (max-width: 768px) {
    .cards {
        flex: 1 1 calc(50% - 24px);
    }
}

@media (max-width: 576px) {
    .cards {
        flex: 1 1 calc(100% - 24px);
    }
}

.title-name {
    color: var(--M3-web-exatracted-error-e-99, #FFFBFF);
    font-family: "IBM Plex Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    /* 120% */
    letter-spacing: 0.32px;
    text-transform: capitalize;
}

.card-content {
    color: var(--M3-web-exatracted-error-e-99, #FFFBFF);
    text-align: justify;
    font-family: "IBM Plex Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 24px 0;
    /* 150% */
}

.available-platform {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    /* padding: 0 16px; */
}

.text-tabs {
    border-radius: 4px;
    background: var(--M3-web-exatracted-secondary-shades-s-95, #ffede9);
    padding: 3px;
    border: 1px solid var(--M3-web-exatracted-secondary-shades-s-50, #E5451A);
}

.digital-text-tabs {
    border-radius: 4px;
    background: var(--M3-web-exatracted-secondary-shades-s-95, #ffede9);
    padding: 3px;
    border: 1px solid var(--M3-web-exatracted-secondary-shades-s-50, #F2BF48);
}

.digitl-boxes-color {
    background-color: #FFEFD4 !important;
}

.avail-color {
    background-color: #E5451A;
    border: 1px solid var(--M3-web-exatracted-secondary-shades-s-95, #FFEDE9);
}

.tabs-button {
    color: var(--M3-web-exatracted-primary-shades-p-20, #002866);
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;
    padding: 8px;
}

@media (max-width: 768px) {
    .main-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px 16px;
    }
}

@media (max-width: 576px) {
    .main-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px 16px;
    }
}

.Outsourcing-container {
    border-radius: 16px;
    background-color: #002866;
}

.Outsourcing-main-content {
    display: flex;
    height: 60px;
    padding: 48px 64px 48px 48px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    color: white;
    margin-top: 60px;
}

.Outsourcing-left-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 4px;
    text-decoration: underline;
    color: white;
}

.Outsourcing-left-content img {
    padding-left: 8px;
}

.Outsourcing-right-content {
    flex: 7;
    display: flex;
    align-items: left;
    justify-content: left;
    font-size: 32px;
    line-height: 40px;
    font-weight: 500px;
}

.Outsourcing-line {
    width: 100px;
    height: 2px;
    background-color: white;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
    .Outsourcing-container {
        flex-direction: column;
    }

    .Outsourcing-right-content {
        flex: 2;
        display: flex;
        flex-direction: column;
    }

    .Outsourcing-left-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: right;
        justify-content: right;
    }
}

@media (max-width: 768px) {
    .zen3-card {
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px 16px;
        margin-top: 40px;
    }

    .offshore {
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px 34px;
    }

    .travel_saas_img img {
        padding: 0 14px;
    }
}

@media (max-width: 576px) {
    .zen3-card {
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px 16px;
        margin-top: 40px;
    }

    .offshore {
        max-width: 1200px;
        margin: 0 auto;
        padding: 5px 14px;
    }

    .travel_saas_img img {
        padding: 0 16px;
    }
}

.offshore {
    display: flex;
    color: var(--M3-web-exatracted-primary-shades-p-20, #002866);
    background-color: #FFF;
    width: 380px;
    font-size: 24px;
    font-weight: 600 !important;
    border-radius: 10px;
    fill: #F0F0FA;
    stroke-width: 1px;
    stroke: var(--M3-web-exatracted-extra-color-g5, #07AF56);
    filter: drop-shadow(12px 12px 0px #07AF56);
    position: 'relative';
    z-index: 1;
}

.shore-text {
    padding: 0 10px;
}

.accounting-image-card {
    border-radius: 32px;
    background-color: #046934;
}

.global-travel-image-card {
    margin-top: 50px;
    border-radius: 32px;
    background-color: #977100;
}


@media only screen and (max-width: 599px) {
    .background-bookings {
        padding: 15px 16px;
    }
    .background-business {
        padding: 15px 16px;
    }
    .background-digital {
        padding: 15px 16px;
    }
    .heading-title br{
        display: none;
    }
    .breadcrumb {
        display: none;
    }
    .heading-title {
        font-family: "IBM Plex Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        justify-content: center;
        text-transform: capitalize;
        color: var(--M3-web-exatracted-primary-shades-p-100, #FFF);
        text-align: center;
        display: flex;
    }

    .paragraph {
        color: var(--M3-web-exatracted-primary-shades-p-100, #FFF);
        text-align: justify;
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .image-container img {
       width: 344px;
       height: 200px;
    }
    .image-container {
        padding: 15px 16px;
    }
    .title-text {
        font-family: "IBM Plex Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        /* 125% */
        /* text-transform: capitalize; */
        color: var(--M3-web-exatracted-primary-shades-p-100, #FFF);
        text-align: center;
        display: flex;
    }
    .horizontal-item {
        display: block;;
    }
    .horizontal-content {
        color: var(--M3-web-exatracted-primary-shades-p-100, #FFF);
        text-align: justify;
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .card-content {
        color: var(--M3-web-exatracted-primary-shades-p-100, #FFF);
        text-align: justify;
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .title-name {
        font-family: "IBM Plex Sans";
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        /* 125% */
        text-transform: capitalize;
        color: var(--M3-web-exatracted-primary-shades-p-100, #FFF);
        text-align: center;
        display: flex;
    }
    .horizontal-paragraph {
        margin-top: 20px;
    }
    .Outsourcing-container {
        display: none;
    }
    .offshore {
        display: flex;
        color: var(--M3-web-exatracted-primary-shades-p-20, #002866);
        background-color: #FFF;
        width: auto;
        font-size: 20px;
        font-weight: 600 !important;
        border-radius: 10px;
        fill: #F0F0FA;
        stroke-width: 1px;
        stroke: var(--M3-web-exatracted-extra-color-g5, #07AF56);
        filter: drop-shadow(12px 12px 0px #07AF56);
    }
    .travel_saas_img {
        margin-bottom: 15px;
    }
    .mobile_vesrion{
        flex-direction: column !important;
      }
      .mobileV{
        order: 2 !important;
      }
      .mobileT{
        order: 1 !important;
      }
      
.horizontal-paragraph img {
    margin-top: 8px;
}
}