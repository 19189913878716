body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans";
  /* background-image: url("./asserts/svgs/heroBanner.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #001233;
}

.w-fit-content {
  width: fit-content;
}

.px-64 {
  padding-left: 64px;
  padding-right: 64px;
}

.py-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.my-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.mx-64 {
  margin-left: 64px;
  margin-right: 64px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-74 {
  margin-top: 74px;
}

.mb-64 {
  margin-bottom: 64px;
}

.btn-uparrow {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 32px;
  background: var(--M3-web-exatracted-primary-shades-p-100, #fff);
  cursor: pointer;
}
.connect-now {
  display: flex;
  color: #001233;
}

.mobile-connect-now{
  display: flex;
  color: #001233;
  padding: 5px 10px;
}

.connect-now:hover {
  color: white;
}

.all-div-connect {
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: var(--M3-web-exatracted-primary-shades-p-100, #fff);
  cursor: pointer;
  color: #001233;
}

.all-div-connect:hover {
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  cursor: pointer;
  background: #172554;
  border: 1px solid white;
}

.btn-uparrow .text {
  color: var(--M3-web-exatracted-primary-shades-p-20, #002866);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
}

.text{
  color: #002286;
}
.titles {
  color: var(--M3-web-exatracted-primary-shades-p-95, #e5e8ff);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 55.7px;
  /* text-transform: capitalize; */
}

.small-tabs {
  border-radius: 4px;
  border: 1px solid var(--M3-web-exatracted-primary-shades-p-20, #002866);
  background: var(--M3-web-exatracted-secondary-shades-s-95, #ffede9);
  padding: 8px;
}

.small-tabs-text {
  color: var(--M3-web-exatracted-primary-shades-p-20, #002866);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.small-tabs-Travel-text{
  color: var(--M3-web-exatracted-primary-shades-p-20, #002866);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}


.btn-uparrow:hover {
  color: white;
  border-radius: 32px;
  border: 1px solid var(--M3-web-exatracted-primary-shades-p-100, #FFF);
  background-color: var(--M3-web-exatracted-primary-shades-p-40, #0057CC);
}

@media only screen and (max-width: 599px) {
  .mt-74 {
    margin-top: 40px;
  }
  .text {
    font-size: 14px !important;
  }
  .titles {
    color: var(--M3-web-exatracted-primary-shades-p-95, #e5e8ff);
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 30px !important;
    text-transform: capitalize;
  }
}