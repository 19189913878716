.footer {
  color: #fff;
  padding: 0 64px;
  max-width: 1440px;
  margin: auto;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #fff;
}

.footerColumn {
  width: auto;
  margin-bottom: 20px;
}

.footerColumn h4 {
  color: var(--M3-web-exatracted-primary-shades-p-99, #FCFCFC);
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 112%; /* 17.92px */
  letter-spacing: -0.16px;
  text-transform: capitalize;
}

.footerColumn ul {
  list-style-type: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.68px;
  letter-spacing: -0.14px;
  text-transform: capitalize;
  margin-bottom: 16px;
  padding: 8px 0 8px 0;
}

.footerColumn li {
  list-style-type: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.68px;
  letter-spacing: -0.14px;
  text-transform: capitalize;
  margin-bottom: 16px;
  padding: 3px 0 3px 0;
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
  color: #fff;
  font-family: "IBM Plex Sans";
  font-size: 14.4px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 0px;
}

.footer-copy {
  display: flex;
  align-items: center;
  gap: 8px;
}

.copy-right-img img{
  padding: 8px;
  height: 24px;
  width: 24px;
}

.contact {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px 12px 12px 12px;
  border-radius: 18px;
  font-family: "IBM Plex Sans";
  border: 1px solid var(--M3-web-exatracted-primary-shades-p-100, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
  width: 175px;
}

@media (min-width: 768px) {
  .footerColumn {
    flex-basis: calc(25% - 20px);
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .footerBottom {
    flex-direction: column;
    align-items: center;
  }
  .footer {
    padding: 15px 30px;
  }
}
